import {dom, defineCustomElement} from "./utils";

/**
 *
 *
 *
 */
export class Tab extends HTMLElement {
    static elementName = "nav-tab"

    constructor() {
        super();
    }

    connectedCallback () {
        if (!this.shadowRoot) {
            this.attachShadow({mode: "open"});
            this.shadowRoot.appendChild(dom(["div", {role: "tab"},
                                             this.getAttribute("title")]));
        }
    }
}

defineCustomElement(Tab.elementName, Tab);

/**
 *
 *
 *
 */
export class TabPanel extends HTMLElement {
    static elementName = "nav-tab-panel"

    constructor() {
        super();
    }

    connectedCallback () {
        if (!this.shadowRoot) {
            this.attachShadow({mode: "open"});
            this.shadowRoot.appendChild(dom(["div", {role: "tabpanel"},
                                             ["slot"]]));
        }
    }
}

defineCustomElement(TabPanel.elementName, TabPanel);

/**
 *
 *
 *
 *
 *
 *
 */
export class Tabs extends HTMLElement {
    static elementName = "nav-tabs"
    static observedAttributes = ["selected-tab"];

    constructor() {
        super();
    }

    connectedCallback() {
        if (!this.shadowRoot) {
            this.attachShadow({mode: "open"});
            this.shadowRoot.appendChild(dom(["div", {role: "tablist",
                                                     part: "navTabsTablist"},
                                             ["slot"]]));
        }
        const defaultTab = this.getAttribute("selected-tab");
        window.o_global.eventQBus.on("ftnav.global-navigation.closed", /*                                          */
            () => this.setAttribute("selected-tab", defaultTab));

        this.addEventListener("click", (e) => {
            const tab = e.target.closest(Tab.elementName);
            if (tab) {
                this.switchTab(tab.getAttribute("name"));
            }
        });
    }

    switchTab(tabName) {
        this.setAttribute("selected-tab", tabName);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === "selected-tab") {
            const selectedTab = this.querySelector(Tab.elementName + '[name="' + newValue + '"]');
            if (selectedTab) {
                this.querySelectorAll(Tab.elementName)
                    .forEach(tab => {
                        const sel = (tab.getAttribute("name") === newValue);
                        tab.setAttribute("aria-selected", sel);
                        tab.setAttribute("tabindex", (sel ? "0" : "-1"));
                    });
                this.parentElement.querySelectorAll(TabPanel.elementName).forEach((element) => {
                    if (element.id === selectedTab.getAttribute("aria-controls")) element.removeAttribute("hidden");
                    else element.setAttribute("hidden", "hidden");
                });
            }

        }
    }
}

defineCustomElement(Tabs.elementName, Tabs);

