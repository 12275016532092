import {track} from "@otto-ec/nav_star-track/src/js/star-track";

export class BrandSliderE3288 extends HTMLElement {

    /*                                                                         */
    static get customElement() {
        return "nav_brand-slider-e3288";
    }

    static get customElementName() {
        return "nav_brand-slider-e3288";
    }

    constructor() {
        super();
    }

    connectedCallback() {
        Array.from(this.getElementsByClassName('nav_brand-slider-e3288__chip'))
            .forEach(entry => {
                entry.addEventListener('click', event => {
                    track(event.target, "user-action/visit-brand-slider-link");
                })
            });
    }
}