import {DesktopTopLevelItem, Flyout, FlyoutContent} from "./desktopElements";
import {BrandSliderE3288} from "./brandSlider";
import {observeMousemove} from "./mouseSpeedTracker";
import "@otto-ec/nav_patternsson/src/js/Tabs";
import {isTouchDevice} from "./utils";

function initializeElement(c) {
    customElements.get(c.customElement) || customElements.define(c.customElement, c);
}

if (!isTouchDevice()) observeMousemove();

initializeElement(DesktopTopLevelItem);
initializeElement(Flyout);
initializeElement(FlyoutContent);
initializeElement(BrandSliderE3288);

/*                                                                                 */
/*                   */
document.addEventListener("click", event => {
    if (!event.target.closest(".nav_desktop-global-navigation")) {
        event.target.querySelectorAll(DesktopTopLevelItem.customElement).forEach(
            (topLevelItem) => {
                if (topLevelItem.isActive()) topLevelItem.exitFlyoutWithTracking();
            }
        );
    }
});